export const lang = () => [
  {
    text1: '最新版本',
    text2: '支持iOS 12以上',
    text3: '支持Android 5.0以上，鸿蒙系统请关闭纯净模式后下载',
    text4: '支持window 7/8/10/11',
    text5: '支持macOS 10.13及以上',
    text6: '一键连接，无需手动配置',
    text7: '全球上千个线路任意选择',
    text8: '流量加密，零日志政策',
    text9: '7*18小时客服支持',
    text10: '立即下载',
    text11: 'App Store下载帮助>>',
    text12: '(不支持中国大陆商店)',
    text13: 'Windows 7/8',
    text14: '支持Ubuntu LTS 16.04以上, 仅支持Intel/AMD的x64处理器',
    text15: '安装说明',
    text16: '关闭',
    text17: '不支持越狱机型',
    text18: '您当前的浏览器可能会导致安装失败。建议使用Safari。',
    text19: '支持macOS 10.13及以上',
    text20: '请登录后再下载',
    text21: '备用下载',
    text22: '其它下载方式',
    text23: '登录',
    text24: '立即订阅',
    text25: 'Windows 10/11',
    text26: 'macOS 10.15',
    text27: 'macOS 10.14',
    text28: '命令行版',
    text29: 'Ubuntu桌面版',
    text30: 'macOS 11及以上',
    title26: '适用于【Intel】处理器，操作系统macOS 10.15以上',
    title30: '适用于【苹果M系列】处理器，操作系统macOS 10.15以上',
  },
  {
    text1: 'Latest Version',
    text2: 'Supports iOS 12.0+',
    text3: 'Supports Android 5.0+, HarmonyOS please turn off the pure mode and download',
    text4: 'Supports window 7/8/10/11',
    text5: 'supports macOS 10.13 and above',
    text6: 'One-click connection without manual configuration',
    text7: 'Thousands of servers worldwide',
    text8: 'Traffic encryption, zero logging policy',
    text9: '7*18 hour customer support',
    text10: 'Download',
    text11: 'App Store download help>>',
    text12: '(Not available on the China App Store.)',
    text13: 'Windows 7/8',
    text14: 'Supports Ubuntu LTS 16.04 and above, Only supports Intel/AMD x64 processors',
    text15: 'Installation Notes',
    text16: 'Close',
    text17: 'Jailbreak phones are not supported',
    text18: 'Your current browser may cause the installation to fail. We recommend using Safari.',
    text19: 'supports macOS 10.13 and above',
    text20: 'Please login to download',
    text21: 'Alternate Download',
    text22: 'Other Downloads',
    text23: 'Login',
    text24: 'Plans',
    text25: 'Windows 10/11',
    text26: 'macOS 10.15',
    text27: 'macOS 10.14',
    text28: 'Command line client',
    text29: 'Ubuntu Desktop',
    text30: 'macOS 11+',
    title26: 'Applicable to [Intel] processor, operating system macOS 10.15 or above',
    title30: 'Applicable to [Apple M series] processor, operating system macOS 10.15 or above',
  },
  {
    text1: '最新版本',
    text2: '支持iOS 12以上',
    text3: '支持Android 5.0以上，鴻蒙系統請關閉純凈模式後下載',
    text4: '支持window 7/8/10/11',
    text5: '支持macOS 10.13及以上',
    text6: '一鍵連接，無需手動配置',
    text7: '全球上千個線路任意選擇',
    text8: '流量加密，零日誌政策',
    text9: '7*18小時客服支持',
    text10: '立即下載',
    text11: 'App Store下載幫助>>',
    text12: '(不支持中國大陸商店)',
    text13: 'Windows 7/8',
    text14: '支持Ubuntu LTS 16.04以上, 僅支持Intel/AMD的x64處理器',
    text15: '安裝說明',
    text16: '關閉',
    text17: '不支持越獄機型',
    text18: '您當前的瀏覽器可能會導致安裝失敗。建議使用Safari。',
    text19: '支持macOS 10.13及以上',
    text20: '請登錄後再下載',
    text21: '備用下載',
    text22: '其它下載方式',
    text23: '登錄',
    text24: '立即訂閱',
    text25: 'Windows 10/11',
    text26: 'macOS 10.15',
    text27: 'macOS 10.14',
    text28: '命令列版',
    text29: 'Ubuntu桌面版',
    text30: 'macOS 11及以上',
    title26: '適用於【Intel】處理器，作業系統macOS 10.15以上',
    title30: '適用於【蘋果M系列】處理器，作業系統macOS 10.15以上',
  },
];

export const faqMap = () => [
  {
    ios: [
      {
        value: '设定信任企业级开发者``',
        img: '/imgs/faq/ios/1-1-ch.png',
      },
      { value: '设置 -> 通用', img: '/imgs/faq/ios/1-2-ch.png' },
      { value: '设备管理', img: '/imgs/faq/ios/1-3-ch.png' },
      {
        value: '找到下图所示企业证书``',
        img: '/imgs/faq/ios/1-4-ch.png',
      },
      { value: '信任', img: '/imgs/faq/ios/1-5-ch.png' },
      { value: '点击 "信任"', img: '/imgs/faq/ios/1-6-ch.png' },
      {
        value: '中国行货手机首次运行时, 允许无线局域网/蜂窝网络数据',
        img: '/imgs/faq/ios/2-1-en.jpg',
      },
      {
        value: '首次运行时允许创建vpn连线',
        img: '/imgs/faq/ios/3-1-ch.png',
      },
      { value: '设置允许需要您确认', img: '/imgs/faq/ios/3-2-ch.png' },
    ],
    super_sign: [
      {
        value: '点击“免费安装”',
        img: '/imgs/faq/super_sign/1.png',
      },
      {
        value: '点击“允许”，下载描述配置文件',
        img: '/imgs/faq/super_sign/2.png',
      },
      {
        value: '安装完描述配置文件，点击”关闭“，再次点击”允许“',
        img: '/imgs/faq/super_sign/3.png',
      },
      {
        value: '找到下图所示已下载的描述文件”QuickQ”',
        img: '/imgs/faq/super_sign/4.png',
      },
      {
        value: '点击右上角”安装“，输入手机密码',
        img: '/imgs/faq/super_sign/5.png',
      },
      {
        value: '继续点击底部弹出显示框的“安装”',
        img: '/imgs/faq/super_sign/6.png',
      },
      {
        value: '在此页面等待一会，会自动弹出“在iTunes中打开此页”的提示窗口',
        img: '/imgs/faq/super_sign/7.png',
      },
      {
        value: '点击“打开”',
        img: '/imgs/faq/super_sign/8.png',
      },
      {
        value: '点击“安装”，开始安装QuickQ，回到手机应用桌面查看下载进度、是否安装成功',
        img: '/imgs/faq/super_sign/9.png',
      },
    ],
    android: [
      { value: '应用程式权限管理', img: '/imgs/faq/android/zh/1.png' },
      { value: '选择"允许背景运行"', img: '/imgs/faq/android/zh/2.png' },
      { value: '允许建立VPN连线', img: '/imgs/faq/android/zh/3.png' },
    ],
    mac: [
      {
        title: '首次打开设置',
        value: '首次打开会有如下提示：',
        img: '/imgs/faq/mac/zh/1.png',
      },
    ],
    mac_installer: [
      {
        value: '双击解压”QuickQ Installer”的zip，首次打开会出现以下提示，点击“打开”按钮',
        img: '/imgs/faq/mac_installer/zh/1.png',
      },
      {
        value: '打开”QuickQ Installer”后，将开始下载”QuickQ”或”QuickQ For MacOS”，下载完成后则会自动退出”QuickQ Installer”和打开”QuickQ”或”QuickQ For MacOS”',
        img: '/imgs/faq/mac_installer/zh/2.png',
      },
    ],
    win: [
      {
        value: '当您打开应用程序是，如果出现如下弹窗，请点击"是"',
        img: '/imgs/faq/win32/zh/1.png',
      },
      {
        value: '当出现如下弹窗时，勾选"不再提醒"，然后打开下拉式功能表',
        img: '/imgs/faq/win32/zh/2.png',
      },
      {
        value: '当出现如下弹窗时，勾选"不再提醒"，然后打开下拉式功能表',
        img: '/imgs/faq/win32/zh/3.png',
      },
      {
        value: '当出现如下弹窗时，勾选"不再提醒"，然后打开下拉式功能表',
        img: '/imgs/faq/win32/zh/4.png',
      },
      {
        value: '当出现如下弹窗时，点击"添加信任"',
        img: '/imgs/faq/win32/zh/5.png',
      },
    ],
    linux: [
      {
        value: '1. 保存下载的文件',
        img: '/imgs/faq/linux/zh/1.png',
      },
      {
        value: '2. 找到下载的文件',
        img: '/imgs/faq/linux/zh/2.png',
      },
      {
        value: '3. 使用软件安装器打开安装包，然后选择安装',
        img: '/imgs/faq/linux/zh/3.png',
      },
    ],
  },
  {
    ios: [
      {
        value: 'Trust our Enterprise Apps``',
        img: '/imgs/faq/ios/1-1-en.jpg',
      },
      { value: 'Settings -> General', img: '/imgs/faq/ios/1-2-en.jpg' },
      {
        value: 'Profiles & Device Management',
        img: '/imgs/faq/ios/1-3-en.jpg',
      },
      {
        value: 'As bellow, ``',
        img: '/imgs/faq/ios/1-4-en.jpg',
      },
      { value: 'Trust', img: '/imgs/faq/ios/1-5-en.jpg' },
      { value: 'Tap "Trust"', img: '/imgs/faq/ios/1-6-en.jpg' },
      {
        value:
          'On china domestic licensed version, when first run app, allowing the WLAN / cellular network data.',
        img: '/imgs/faq/ios/2-1-en.jpg',
      },
      {
        value: 'When first connect vpn, allow add vpn configuration.',
        img: '/imgs/faq/ios/3-1-en.jpg',
      },
      {
        value: 'Setting allows you to confirm.',
        img: '/imgs/faq/ios/3-2-en.jpg',
      },
    ],
    super_sign: [
      {
        value: '点击“免费安装”',
        img: '/imgs/faq/super_sign/1.png',
      },
      {
        value: '点击“允许”，下载描述配置文件',
        img: '/imgs/faq/super_sign/2.png',
      },
      {
        value: '安装完描述配置文件，点击”关闭“，再次点击”允许“',
        img: '/imgs/faq/super_sign/3.png',
      },
      {
        value: '找到下图所示已下载的描述文件”QuickQ”',
        img: '/imgs/faq/super_sign/4.png',
      },
      {
        value: '点击右上角”安装“，输入手机密码',
        img: '/imgs/faq/super_sign/5.png',
      },
      {
        value: '继续点击底部弹出显示框的“安装”',
        img: '/imgs/faq/super_sign/6.png',
      },
      {
        value: '在此页面等待一会，会自动弹出“在iTunes中打开此页”的提示窗口',
        img: '/imgs/faq/super_sign/7.png',
      },
      {
        value: '点击“打开”',
        img: '/imgs/faq/super_sign/8.png',
      },
      {
        value: '点击“安装”，开始安装QuickQ，回到手机应用桌面查看下载进度、是否安装成功',
        img: '/imgs/faq/super_sign/9.png',
      },
    ],
    android: [
      { value: 'Permissions', img: '/imgs/faq/android/en/1.png' },
      {
        value: 'Select "Allow running in background"',
        img: '/imgs/faq/android/en/2.png',
      },
      {
        value: 'Allow create a VPN connection',
        img: '/imgs/faq/android/en/3.png',
      },
    ],
    mac: [
      {
        title: 'First run setup',
        value:
          'The first time you open QuickQ, you will get the following prompt:',
        img: '/imgs/faq/mac/en/1.png',
      },
    ],
    mac_installer: [
      {
        value: 'Double-click the “QuickQ Installer” zip to open it for the first time. Click open',
        img: '/imgs/faq/mac_installer/en/1.png',
      },
      {
        value: '“QuickQ Installer” starts to download “QuickQ” or “QuickQ For MacOS”, and automatically exits “”QuickQ Installer”  and starts “QuickQ” or “QuickQ For MacOS.”',
        img: '/imgs/faq/mac_installer/en/2.png',
      },
    ],
    win: [
      {
        value:
          'When you open the application, if the following pop-up appears, please click "yes".',
        img: '/imgs/faq/win32/zh/1.png',
      },
      {
        value:
          'When the following pop-up occurs, check "不再提醒", then open the drop-down menu.',
        img: '/imgs/faq/win32/zh/2.png',
      },
      {
        value:
          'When the following pop-up occurs, check "不再提醒", then open the drop-down menu.',
        img: '/imgs/faq/win32/zh/3.png',
      },
      {
        value:
          'When the following pop-up occurs, check "不再提醒", then open the drop-down menu.',
        img: '/imgs/faq/win32/zh/4.png',
      },
      {
        value: 'When the following pop-up occurs, click "添加信任"',
        img: '/imgs/faq/win32/zh/5.png',
      },
    ],
    linux: [
      {
        value: '1. Save the downloaded file',
        img: '/imgs/faq/linux/en/1.png',
      },
      {
        value: '2. Find the downloaded file',
        img: '/imgs/faq/linux/en/2.png',
      },
      {
        value: '3. Use the software installer to open the installation package, and then select install',
        img: '/imgs/faq/linux/en/3.png',
      },
    ],
  },
  {
    ios: [
      {
        value: '設定信任企業級開發者``',
        img: '/imgs/faq/ios/1-1-tc.jpg',
      },
      { value: '设定 -> 一般', img: '/imgs/faq/ios/1-2-tc.jpg' },
      { value: '描述檔與裝置管理', img: '/imgs/faq/ios/1-3-tc.jpg' },
      {
        value: '找到下圖所示企業證書``',
        img: '/imgs/faq/ios/1-4-tc.jpg',
      },
      { value: '信任', img: '/imgs/faq/ios/1-5-tc.jpg' },
      { value: '點擊 "信任"', img: '/imgs/faq/ios/1-6-tc.jpg' },
      {
        value: '中國行貨手機首次運行時, 允許無線局域網/蜂窩網絡數據',
        img: '/imgs/faq/ios/2-1-en.jpg',
      },
      {
        value: '首次運行時允許創建vpn連線',
        img: '/imgs/faq/ios/3-1-en.jpg',
      },
      { value: '設置允許需要您確認', img: '/imgs/faq/ios/3-2-en.jpg' },
    ],
    super_sign: [
      {
        value: '点击“免费安装”',
        img: '/imgs/faq/super_sign/1.png',
      },
      {
        value: '点击“允许”，下载描述配置文件',
        img: '/imgs/faq/super_sign/2.png',
      },
      {
        value: '安装完描述配置文件，点击”关闭“，再次点击”允许“',
        img: '/imgs/faq/super_sign/3.png',
      },
      {
        value: '找到下图所示已下载的描述文件”QuickQ”',
        img: '/imgs/faq/super_sign/4.png',
      },
      {
        value: '点击右上角”安装“，输入手机密码',
        img: '/imgs/faq/super_sign/5.png',
      },
      {
        value: '继续点击底部弹出显示框的“安装”',
        img: '/imgs/faq/super_sign/6.png',
      },
      {
        value: '在此页面等待一会，会自动弹出“在iTunes中打开此页”的提示窗口',
        img: '/imgs/faq/super_sign/7.png',
      },
      {
        value: '点击“打开”',
        img: '/imgs/faq/super_sign/8.png',
      },
      {
        value: '点击“安装”，开始安装QuickQ，回到手机应用桌面查看下载进度、是否安装成功',
        img: '/imgs/faq/super_sign/9.png',
      },
    ],
    android: [
      { value: '應用程式權限管理', img: '/imgs/faq/android/zh/1.png' },
      { value: '選擇"允許背景運行"', img: '/imgs/faq/android/zh/2.png' },
      { value: '允許建立VPN連線', img: '/imgs/faq/android/zh/3.png' },
    ],
    mac: [
      {
        title: '首次打開設置',
        value: '首次打開會有如下提示：',
        img: '/imgs/faq/mac/zh/1.png',
      },
    ],
    mac_installer: [
      {
        value: '雙擊解壓”QuickQ Installer”的zip，首次打開會出現以下提示，點擊“打開”按鈕',
        img: '/imgs/faq/mac_installer/zh/1.png',
      },
      {
        value: '打開”QuickQ Installer”後，將開始下載”QuickQ”或”QuickQ For MacOS”，下載完成後則會自動退出”QuickQ Installer”和打開”QuickQ”或”QuickQ For MacOS”',
        img: '/imgs/faq/mac_installer/zh/2.png',
      },
    ],
    win: [
      {
        value: '當您打開應用程序是，如果出現如下彈窗，請點擊"是"',
        img: '/imgs/faq/win32/zh/1.png',
      },
      {
        value: '當出現如下彈窗時，勾選"不再提醒"，然後打開下拉式功能表',
        img: '/imgs/faq/win32/zh/2.png',
      },
      {
        value: '當出現如下彈窗時，勾選"不再提醒"，然後打開下拉式功能表',
        img: '/imgs/faq/win32/zh/3.png',
      },
      {
        value: '當出現如下彈窗時，勾選"不再提醒"，然後打開下拉式功能表',
        img: '/imgs/faq/win32/zh/4.png',
      },
      {
        value: '當出現如下彈窗時，點擊"添加信任"',
        img: '/imgs/faq/win32/zh/5.png',
      },
    ],
    linux: [
      {
        value: '1. 保存下載的文件',
        img: '/imgs/faq/linux/zh/1.png',
      },
      {
        value: '2. 找到下載的文件',
        img: '/imgs/faq/linux/zh/2.png',
      },
      {
        value: '3. 使用軟件安裝器打開安裝包，然後選擇安裝',
        img: '/imgs/faq/linux/zh/3.png',
      },
    ],
  },
];
